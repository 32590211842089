import Constants from 'expo-constants'
import { dark as darkTheme } from '@eva-design/eva'

const {
  THEME_CUSTOMIZATION={},
} = Constants.expoConfig.extra

const theme = {
  ...darkTheme,

  // Dark theme defaults below.
  // Check here for updates: https://github.com/eva-design/eva/blob/master/packages/eva/themes/dark.json

  // "color-primary-100": "#F2F6FF",
  // "color-primary-200": "#D9E4FF",
  // "color-primary-300": "#A6C1FF",
  // "color-primary-400": "#598BFF",
  // "color-primary-500": "#3366FF",
  // "color-primary-600": "#274BDB",
  // "color-primary-700": "#1A34B8",
  // "color-primary-800": "#102694",
  // "color-primary-900": "#091C7A",

  // "color-primary-transparent-100": "rgba(51, 102, 255, 0.08)",
  // "color-primary-transparent-200": "rgba(51, 102, 255, 0.16)",
  // "color-primary-transparent-300": "rgba(51, 102, 255, 0.24)",
  // "color-primary-transparent-400": "rgba(51, 102, 255, 0.32)",
  // "color-primary-transparent-500": "rgba(51, 102, 255, 0.40)",
  // "color-primary-transparent-600": "rgba(51, 102, 255, 0.48)",

  // "color-success-100": "#EDFFF3",
  // "color-success-200": "#B3FFD6",
  // "color-success-300": "#8CFAC7",
  // "color-success-400": "#51F0B0",
  // "color-success-500": "#00E096",
  // "color-success-600": "#00B383",
  // "color-success-700": "#008F72",
  // "color-success-800": "#007566",
  // "color-success-900": "#00524C",

  // "color-success-transparent-100": "rgba(0, 224, 150, 0.08)",
  // "color-success-transparent-200": "rgba(0, 224, 150, 0.16)",
  // "color-success-transparent-300": "rgba(0, 224, 150, 0.24)",
  // "color-success-transparent-400": "rgba(0, 224, 150, 0.32)",
  // "color-success-transparent-500": "rgba(0, 224, 150, 0.40)",
  // "color-success-transparent-600": "rgba(0, 224, 150, 0.48)",

  // "color-info-100": "#F2F8FF",
  // "color-info-200": "#C7E2FF",
  // "color-info-300": "#94CBFF",
  // "color-info-400": "#42AAFF",
  // "color-info-500": "#0095FF",
  // "color-info-600": "#006FD6",
  // "color-info-700": "#0057C2",
  // "color-info-800": "#0041A8",
  // "color-info-900": "#002885",

  // "color-info-transparent-100": "rgba(0, 149, 255, 0.08)",
  // "color-info-transparent-200": "rgba(0, 149, 255, 0.16)",
  // "color-info-transparent-300": "rgba(0, 149, 255, 0.24)",
  // "color-info-transparent-400": "rgba(0, 149, 255, 0.32)",
  // "color-info-transparent-500": "rgba(0, 149, 255, 0.40)",
  // "color-info-transparent-600": "rgba(0, 149, 255, 0.48)",

  // "color-warning-100": "#FFFDF2",
  // "color-warning-200": "#FFF1C2",
  // "color-warning-300": "#FFE59E",
  // "color-warning-400": "#FFC94D",
  // "color-warning-500": "#FFAA00",
  // "color-warning-600": "#DB8B00",
  // "color-warning-700": "#B86E00",
  // "color-warning-800": "#945400",
  // "color-warning-900": "#703C00",

  // "color-warning-transparent-100": "rgba(255, 170, 0, 0.08)",
  // "color-warning-transparent-200": "rgba(255, 170, 0, 0.16)",
  // "color-warning-transparent-300": "rgba(255, 170, 0, 0.24)",
  // "color-warning-transparent-400": "rgba(255, 170, 0, 0.32)",
  // "color-warning-transparent-500": "rgba(255, 170, 0, 0.40)",
  // "color-warning-transparent-600": "rgba(255, 170, 0, 0.48)",

  // "color-danger-100": "#FFF2F2",
  // "color-danger-200": "#FFD6D9",
  // "color-danger-300": "#FFA8B4",
  // "color-danger-400": "#FF708D",
  // "color-danger-500": "#FF3D71",
  // "color-danger-600": "#DB2C66",
  // "color-danger-700": "#B81D5B",
  // "color-danger-800": "#94124E",
  // "color-danger-900": "#700940",

  // "color-danger-transparent-100": "rgba(255, 61, 113, 0.08)",
  // "color-danger-transparent-200": "rgba(255, 61, 113, 0.16)",
  // "color-danger-transparent-300": "rgba(255, 61, 113, 0.24)",
  // "color-danger-transparent-400": "rgba(255, 61, 113, 0.32)",
  // "color-danger-transparent-500": "rgba(255, 61, 113, 0.40)",
  // "color-danger-transparent-600": "rgba(255, 61, 113, 0.48)",

  // "color-basic-100": "#FFFFFF",
  // "color-basic-200": "#F7F9FC",
  // "color-basic-300": "#EDF1F7",
  // "color-basic-400": "#E4E9F2",
  // "color-basic-500": "#C5CEE0",
  // "color-basic-600": "#8F9BB3",
  // "color-basic-700": "#2E3A59",
  // "color-basic-800": "#222B45",
  // "color-basic-900": "#1A2138",
  // "color-basic-1000": "#151A30",
  // "color-basic-1100": "#101426",

  // "color-basic-transparent-100": "rgba(143, 155, 179, 0.08)",
  // "color-basic-transparent-200": "rgba(143, 155, 179, 0.16)",
  // "color-basic-transparent-300": "rgba(143, 155, 179, 0.24)",
  // "color-basic-transparent-400": "rgba(143, 155, 179, 0.32)",
  // "color-basic-transparent-500": "rgba(143, 155, 179, 0.40)",
  // "color-basic-transparent-600": "rgba(143, 155, 179, 0.48)",

  // "color-basic-control-transparent-100": "rgba(255, 255, 255, 0.08)",
  // "color-basic-control-transparent-200": "rgba(255, 255, 255, 0.16)",
  // "color-basic-control-transparent-300": "rgba(255, 255, 255, 0.24)",
  // "color-basic-control-transparent-400": "rgba(255, 255, 255, 0.32)",
  // "color-basic-control-transparent-500": "rgba(255, 255, 255, 0.40)",
  // "color-basic-control-transparent-600": "rgba(255, 255, 255, 0.48)",

  // "color-basic-focus": "$color-basic-400",
  // "color-basic-hover": "$color-basic-200",
  // "color-basic-default": "$color-basic-300",
  // "color-basic-active": "$color-basic-400",
  // "color-basic-disabled": "$color-basic-transparent-300",
  // "color-basic-focus-border": "$color-basic-500",
  // "color-basic-hover-border": "$color-basic-hover",
  // "color-basic-default-border": "$color-basic-default",
  // "color-basic-active-border": "$color-basic-active",
  // "color-basic-disabled-border": "$color-basic-disabled",

  // "color-basic-transparent-focus": "$color-basic-transparent-300",
  // "color-basic-transparent-hover": "$color-basic-transparent-200",
  // "color-basic-transparent-default": "$color-basic-transparent-100",
  // "color-basic-transparent-active": "$color-basic-transparent-300",
  // "color-basic-transparent-disabled": "$color-basic-transparent-200",
  // "color-basic-transparent-focus-border": "$color-basic-600",
  // "color-basic-transparent-hover-border": "$color-basic-600",
  // "color-basic-transparent-default-border": "$color-basic-600",
  // "color-basic-transparent-active-border": "$color-basic-600",
  // "color-basic-transparent-disabled-border": "$color-basic-transparent-300",

  // "color-primary-focus": "$color-primary-600",
  // "color-primary-hover": "$color-primary-400",
  // "color-primary-default": "$color-primary-500",
  // "color-primary-active": "$color-primary-600",
  // "color-primary-disabled": "$color-basic-transparent-300",
  // "color-primary-focus-border": "$color-primary-700",
  // "color-primary-hover-border": "$color-primary-hover",
  // "color-primary-default-border": "$color-primary-default",
  // "color-primary-active-border": "$color-primary-active",
  // "color-primary-disabled-border": "$color-primary-disabled",

  // "color-primary-transparent-focus": "$color-primary-transparent-300",
  // "color-primary-transparent-hover": "$color-primary-transparent-200",
  // "color-primary-transparent-default": "$color-primary-transparent-100",
  // "color-primary-transparent-active": "$color-primary-transparent-300",
  // "color-primary-transparent-disabled": "$color-basic-transparent-200",
  // "color-primary-transparent-focus-border": "$color-primary-500",
  // "color-primary-transparent-hover-border": "$color-primary-500",
  // "color-primary-transparent-default-border": "$color-primary-500",
  // "color-primary-transparent-active-border": "$color-primary-500",
  // "color-primary-transparent-disabled-border": "$color-basic-transparent-300",

  // "color-success-focus": "$color-success-600",
  // "color-success-hover": "$color-success-400",
  // "color-success-default": "$color-success-500",
  // "color-success-active": "$color-success-600",
  // "color-success-disabled": "$color-basic-transparent-300",
  // "color-success-focus-border": "$color-success-700",
  // "color-success-hover-border": "$color-success-hover",
  // "color-success-default-border": "$color-success-default",
  // "color-success-active-border": "$color-success-active",
  // "color-success-disabled-border": "$color-success-disabled",

  // "color-success-transparent-focus": "$color-success-transparent-300",
  // "color-success-transparent-hover": "$color-success-transparent-200",
  // "color-success-transparent-default": "$color-success-transparent-100",
  // "color-success-transparent-active": "$color-success-transparent-300",
  // "color-success-transparent-disabled": "$color-basic-transparent-200",
  // "color-success-transparent-focus-border": "$color-success-500",
  // "color-success-transparent-hover-border": "$color-success-500",
  // "color-success-transparent-default-border": "$color-success-500",
  // "color-success-transparent-active-border": "$color-success-500",
  // "color-success-transparent-disabled-border": "$color-basic-transparent-300",

  // "color-info-focus": "$color-info-600",
  // "color-info-hover": "$color-info-400",
  // "color-info-default": "$color-info-500",
  // "color-info-active": "$color-info-600",
  // "color-info-disabled": "$color-basic-transparent-300",
  // "color-info-focus-border": "$color-info-700",
  // "color-info-hover-border": "$color-info-hover",
  // "color-info-default-border": "$color-info-default",
  // "color-info-active-border": "$color-info-active",
  // "color-info-disabled-border": "$color-info-disabled",

  // "color-info-transparent-focus": "$color-info-transparent-300",
  // "color-info-transparent-hover": "$color-info-transparent-200",
  // "color-info-transparent-default": "$color-info-transparent-100",
  // "color-info-transparent-active": "$color-info-transparent-300",
  // "color-info-transparent-disabled": "$color-basic-transparent-200",
  // "color-info-transparent-focus-border": "$color-info-500",
  // "color-info-transparent-hover-border": "$color-info-500",
  // "color-info-transparent-default-border": "$color-info-500",
  // "color-info-transparent-active-border": "$color-info-500",
  // "color-info-transparent-disabled-border": "$color-basic-transparent-300",

  // "color-warning-focus": "$color-warning-600",
  // "color-warning-hover": "$color-warning-400",
  // "color-warning-default": "$color-warning-500",
  // "color-warning-active": "$color-warning-600",
  // "color-warning-disabled": "$color-basic-transparent-300",
  // "color-warning-focus-border": "$color-warning-700",
  // "color-warning-hover-border": "$color-warning-hover",
  // "color-warning-default-border": "$color-warning-default",
  // "color-warning-active-border": "$color-warning-active",
  // "color-warning-disabled-border": "$color-warning-disabled",

  // "color-warning-transparent-focus": "$color-warning-transparent-300",
  // "color-warning-transparent-hover": "$color-warning-transparent-200",
  // "color-warning-transparent-default": "$color-warning-transparent-100",
  // "color-warning-transparent-active": "$color-warning-transparent-300",
  // "color-warning-transparent-disabled": "$color-basic-transparent-200",
  // "color-warning-transparent-focus-border": "$color-warning-500",
  // "color-warning-transparent-hover-border": "$color-warning-500",
  // "color-warning-transparent-default-border": "$color-warning-500",
  // "color-warning-transparent-active-border": "$color-warning-500",
  // "color-warning-transparent-disabled-border": "$color-basic-transparent-300",

  // "color-danger-focus": "$color-danger-600",
  // "color-danger-hover": "$color-danger-400",
  // "color-danger-default": "$color-danger-500",
  // "color-danger-active": "$color-danger-600",
  // "color-danger-disabled": "$color-basic-transparent-300",
  // "color-danger-focus-border": "color-danger-700",
  // "color-danger-hover-border": "$color-danger-hover",
  // "color-danger-default-border": "$color-danger-default",
  // "color-danger-active-border": "$color-danger-active",
  // "color-danger-disabled-border": "$color-danger-disabled",

  // "color-danger-transparent-focus": "$color-danger-transparent-300",
  // "color-danger-transparent-hover": "$color-danger-transparent-200",
  // "color-danger-transparent-default": "$color-danger-transparent-100",
  // "color-danger-transparent-active": "$color-danger-transparent-300",
  // "color-danger-transparent-disabled": "$color-basic-transparent-200",
  // "color-danger-transparent-focus-border": "$color-danger-500",
  // "color-danger-transparent-hover-border": "$color-danger-500",
  // "color-danger-transparent-default-border": "$color-danger-500",
  // "color-danger-transparent-active-border": "$color-danger-500",
  // "color-danger-transparent-disabled-border": "$color-basic-transparent-300",

  // "color-control-focus": "$color-basic-300",
  // "color-control-hover": "$color-basic-200",
  // "color-control-default": "$color-basic-100",
  // "color-control-active": "$color-basic-300",
  // "color-control-disabled": "$color-basic-transparent-300",
  // "color-control-focus-border": "$color-basic-500",
  // "color-control-hover-border": "$color-control-hover",
  // "color-control-default-border": "$color-control-default",
  // "color-control-active-border": "$color-control-active",
  // "color-control-disabled-border": "$color-control-disabled",

  // "color-control-transparent-focus": "$color-basic-control-transparent-300",
  // "color-control-transparent-hover": "$color-basic-control-transparent-200",
  // "color-control-transparent-default": "$color-basic-control-transparent-100",
  // "color-control-transparent-active": "$color-basic-control-transparent-300",
  // "color-control-transparent-disabled": "$color-basic-transparent-200",
  // "color-control-transparent-focus-border": "$color-basic-100",
  // "color-control-transparent-hover-border": "$color-basic-100",
  // "color-control-transparent-default-border": "$color-basic-100",
  // "color-control-transparent-active-border": "$color-basic-100",
  // "color-control-transparent-disabled-border": "$color-basic-transparent-300",

  // "background-basic-color-1": "$color-basic-800",
  // "background-basic-color-2": "$color-basic-900",
  // "background-basic-color-3": "$color-basic-1000",
  // "background-basic-color-4": "$color-basic-1100",

  // "background-alternative-color-1": "$color-basic-100",
  // "background-alternative-color-2": "$color-basic-200",
  // "background-alternative-color-3": "$color-basic-300",
  // "background-alternative-color-4": "$color-basic-400",

  // "border-basic-color-1": "$color-basic-800",
  // "border-basic-color-2": "$color-basic-900",
  // "border-basic-color-3": "$color-basic-1000",
  // "border-basic-color-4": "$color-basic-1100",
  // "border-basic-color-5": "$color-basic-1100",

  // "border-alternative-color-1": "$color-basic-100",
  // "border-alternative-color-2": "$color-basic-200",
  // "border-alternative-color-3": "$color-basic-300",
  // "border-alternative-color-4": "$color-basic-400",
  // "border-alternative-color-5": "$color-basic-500",

  // "border-primary-color-1": "$color-primary-500",
  // "border-primary-color-2": "$color-primary-600",
  // "border-primary-color-3": "$color-primary-700",
  // "border-primary-color-4": "$color-primary-800",
  // "border-primary-color-5": "$color-primary-900",

  // "border-success-color-1": "$color-success-500",
  // "border-success-color-2": "$color-success-600",
  // "border-success-color-3": "$color-success-700",
  // "border-success-color-4": "$color-success-800",
  // "border-success-color-5": "$color-success-900",

  // "border-info-color-1": "$color-info-500",
  // "border-info-color-2": "$color-info-600",
  // "border-info-color-3": "$color-info-700",
  // "border-info-color-4": "$color-info-800",
  // "border-info-color-5": "$color-info-900",

  // "border-warning-color-1": "$color-warning-500",
  // "border-warning-color-2": "$color-warning-600",
  // "border-warning-color-3": "$color-warning-700",
  // "border-warning-color-4": "$color-warning-800",
  // "border-warning-color-5": "$color-warning-900",

  // "border-danger-color-1": "$color-danger-500",
  // "border-danger-color-2": "$color-danger-600",
  // "border-danger-color-3": "$color-danger-700",
  // "border-danger-color-4": "$color-danger-800",
  // "border-danger-color-5": "$color-danger-900",

  // "text-basic-color": "$color-basic-100",
  // "text-alternate-color": "$color-basic-900",
  // "text-control-color": "$color-basic-100",
  // "text-disabled-color": "$color-basic-transparent-600",
  // "text-hint-color": "$color-basic-600",

  // "text-primary-color": "$color-primary-default",
  // "text-primary-focus-color": "$color-primary-focus",
  // "text-primary-hover-color": "$color-primary-hover",
  // "text-primary-active-color": "$color-primary-active",
  // "text-primary-disabled-color": "$color-primary-400",

  // "text-success-color": "$color-success-default",
  // "text-success-focus-color": "$color-success-focus",
  // "text-success-hover-color": "$color-success-hover",
  // "text-success-active-color": "$color-success-active",
  // "text-success-disabled-color": "$color-success-400",

  // "text-info-color": "$color-info-default",
  // "text-info-focus-color": "$color-info-focus",
  // "text-info-hover-color": "$color-info-hover",
  // "text-info-active-color": "$color-info-active",
  // "text-info-disabled-color": "$color-info-400",

  // "text-warning-color": "$color-warning-default",
  // "text-warning-focus-color": "$color-warning-focus",
  // "text-warning-hover-color": "$color-warning-hover",
  // "text-warning-active-color": "$color-warning-active",
  // "text-warning-disabled-color": "$color-warning-400",

  // "text-danger-color": "$color-danger-default",
  // "text-danger-focus-color": "$color-danger-focus",
  // "text-danger-hover-color": "$color-danger-hover",
  // "text-danger-active-color": "$color-danger-active",
  // "text-danger-disabled-color": "$color-danger-400",

  // "outline-color": "$color-basic-700"

  ...(THEME_CUSTOMIZATION.dark || {}),
}

export default theme